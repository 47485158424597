import { default as affiliatescSRXr5OH8lMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsgOfdosIrScMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngos97q2yCgylNMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93RNi2tuPg8bMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as index6aVeaUloqiMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93no9HEA9K0BMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as index5A8zSna9zRMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93qeMjTNKucoMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93WbcxmsaUVmMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editgTnNpIwYXXMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index1kDREJsTsAMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticskYdYa9KMy8Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexoyBNh1r2tXMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexjR0EyHgQzjMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareHy3a1MahQSMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showUJ5vTbLmvvMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexFKPkrxQNVhMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsla1Ns3gwWuMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidesJdQ5eWPnviMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homejygyUwDtXpMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexkUoCGsbqTnMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsWX6RsfkpXXMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as login1veKpceZuaMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesmc8ukcoO1wMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencerszDwsuIdQTPMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93D4Or7iKoGSMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackKd4IPgG8wsMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackbmnEIdRuxXMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callback8O7shCSXXJMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partners7kKSPZjPL3Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93nhNlBOnnLsMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexI2TzBn44W6Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingNOez5k7CGrMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyOj6mohxD9sMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as register1LOHAQdOuuMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokens7DiugE4cpqMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountHc4Lss7dP7Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminFW3FaZMmUBMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingDQrE1aTO4IMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsm80SPrGFBXMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainTZREgEGVOPMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settings4OViNC42vZMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as index6OqrZT2ni6Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordesFxNNt2eJMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileu6G6LkvPhBMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspaces8ar38UJQSAMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsUjzHurzjeBMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorotCLwvLmCjMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successYHpdsCBoTWMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsweDgkOupcOMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editklgKf5Mwz4Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexFeevTZNbC9Meta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexMoK3dNifoPMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as sharexbrgjb9z3zMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showS6hgRItdgQMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexZbhG8udV1xMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexUMRyBw0xeNMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsQxIOiopKAtMeta } from "/codebuild/output/src1283008905/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatescSRXr5OH8lMeta?.name ?? "affiliates",
    path: affiliatescSRXr5OH8lMeta?.path ?? "/affiliates",
    meta: affiliatescSRXr5OH8lMeta || {},
    alias: affiliatescSRXr5OH8lMeta?.alias || [],
    redirect: affiliatescSRXr5OH8lMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsgOfdosIrScMeta?.name ?? "database-views",
    path: database_45viewsgOfdosIrScMeta?.path ?? "/database-views",
    meta: database_45viewsgOfdosIrScMeta || {},
    alias: database_45viewsgOfdosIrScMeta?.alias || [],
    redirect: database_45viewsgOfdosIrScMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngos97q2yCgylNMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngos97q2yCgylNMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngos97q2yCgylNMeta || {},
    alias: discount_45students_45academics_45ngos97q2yCgylNMeta?.alias || [],
    redirect: discount_45students_45academics_45ngos97q2yCgylNMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RNi2tuPg8bMeta?.name ?? "features-slug",
    path: _91slug_93RNi2tuPg8bMeta?.path ?? "/features/:slug()",
    meta: _91slug_93RNi2tuPg8bMeta || {},
    alias: _91slug_93RNi2tuPg8bMeta?.alias || [],
    redirect: _91slug_93RNi2tuPg8bMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: index6aVeaUloqiMeta?.name ?? "features",
    path: index6aVeaUloqiMeta?.path ?? "/features",
    meta: index6aVeaUloqiMeta || {},
    alias: index6aVeaUloqiMeta?.alias || [],
    redirect: index6aVeaUloqiMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93no9HEA9K0BMeta?.name ?? "form-templates-slug",
    path: _91slug_93no9HEA9K0BMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93no9HEA9K0BMeta || {},
    alias: _91slug_93no9HEA9K0BMeta?.alias || [],
    redirect: _91slug_93no9HEA9K0BMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5A8zSna9zRMeta?.name ?? "form-templates",
    path: index5A8zSna9zRMeta?.path ?? "/form-templates",
    meta: index5A8zSna9zRMeta || {},
    alias: index5A8zSna9zRMeta?.alias || [],
    redirect: index5A8zSna9zRMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qeMjTNKucoMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93qeMjTNKucoMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93qeMjTNKucoMeta || {},
    alias: _91slug_93qeMjTNKucoMeta?.alias || [],
    redirect: _91slug_93qeMjTNKucoMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WbcxmsaUVmMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93WbcxmsaUVmMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93WbcxmsaUVmMeta || {},
    alias: _91slug_93WbcxmsaUVmMeta?.alias || [],
    redirect: _91slug_93WbcxmsaUVmMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editgTnNpIwYXXMeta?.name ?? "forms-slug-edit",
    path: editgTnNpIwYXXMeta?.path ?? "/forms/:slug()/edit",
    meta: editgTnNpIwYXXMeta || {},
    alias: editgTnNpIwYXXMeta?.alias || [],
    redirect: editgTnNpIwYXXMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1kDREJsTsAMeta?.name ?? "forms-slug",
    path: index1kDREJsTsAMeta?.path ?? "/forms/:slug()",
    meta: index1kDREJsTsAMeta || {},
    alias: index1kDREJsTsAMeta?.alias || [],
    redirect: index1kDREJsTsAMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showUJ5vTbLmvvMeta?.name ?? undefined,
    path: showUJ5vTbLmvvMeta?.path ?? "/forms/:slug()/show",
    meta: showUJ5vTbLmvvMeta || {},
    alias: showUJ5vTbLmvvMeta?.alias || [],
    redirect: showUJ5vTbLmvvMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticskYdYa9KMy8Meta?.name ?? "forms-slug-show-analytics",
    path: analyticskYdYa9KMy8Meta?.path ?? "analytics",
    meta: analyticskYdYa9KMy8Meta || {},
    alias: analyticskYdYa9KMy8Meta?.alias || [],
    redirect: analyticskYdYa9KMy8Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexoyBNh1r2tXMeta?.name ?? "forms-slug-show",
    path: indexoyBNh1r2tXMeta?.path ?? "",
    meta: indexoyBNh1r2tXMeta || {},
    alias: indexoyBNh1r2tXMeta?.alias || [],
    redirect: indexoyBNh1r2tXMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexjR0EyHgQzjMeta?.name ?? "forms-slug-show-integrations",
    path: indexjR0EyHgQzjMeta?.path ?? "integrations",
    meta: indexjR0EyHgQzjMeta || {},
    alias: indexjR0EyHgQzjMeta?.alias || [],
    redirect: indexjR0EyHgQzjMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareHy3a1MahQSMeta?.name ?? "forms-slug-show-share",
    path: shareHy3a1MahQSMeta?.path ?? "share",
    meta: shareHy3a1MahQSMeta || {},
    alias: shareHy3a1MahQSMeta?.alias || [],
    redirect: shareHy3a1MahQSMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFKPkrxQNVhMeta?.name ?? "forms-create",
    path: indexFKPkrxQNVhMeta?.path ?? "/forms/create",
    meta: indexFKPkrxQNVhMeta || {},
    alias: indexFKPkrxQNVhMeta?.alias || [],
    redirect: indexFKPkrxQNVhMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsla1Ns3gwWuMeta?.name ?? "forms-uploads",
    path: uploadsla1Ns3gwWuMeta?.path ?? "/forms/uploads",
    meta: uploadsla1Ns3gwWuMeta || {},
    alias: uploadsla1Ns3gwWuMeta?.alias || [],
    redirect: uploadsla1Ns3gwWuMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidesJdQ5eWPnviMeta?.name ?? "guides",
    path: guidesJdQ5eWPnviMeta?.path ?? "/guides",
    meta: guidesJdQ5eWPnviMeta || {},
    alias: guidesJdQ5eWPnviMeta?.alias || [],
    redirect: guidesJdQ5eWPnviMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homejygyUwDtXpMeta?.name ?? "home",
    path: homejygyUwDtXpMeta?.path ?? "/home",
    meta: homejygyUwDtXpMeta || {},
    alias: homejygyUwDtXpMeta?.alias || [],
    redirect: homejygyUwDtXpMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexkUoCGsbqTnMeta?.name ?? "index",
    path: indexkUoCGsbqTnMeta?.path ?? "/",
    meta: indexkUoCGsbqTnMeta || {},
    alias: indexkUoCGsbqTnMeta?.alias || [],
    redirect: indexkUoCGsbqTnMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsWX6RsfkpXXMeta?.name ?? "integrations",
    path: integrationsWX6RsfkpXXMeta?.path ?? "/integrations",
    meta: integrationsWX6RsfkpXXMeta || {},
    alias: integrationsWX6RsfkpXXMeta?.alias || [],
    redirect: integrationsWX6RsfkpXXMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: login1veKpceZuaMeta?.name ?? "login",
    path: login1veKpceZuaMeta?.path ?? "/login",
    meta: login1veKpceZuaMeta || {},
    alias: login1veKpceZuaMeta?.alias || [],
    redirect: login1veKpceZuaMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesmc8ukcoO1wMeta?.name ?? "my-templates",
    path: my_45templatesmc8ukcoO1wMeta?.path ?? "/my-templates",
    meta: my_45templatesmc8ukcoO1wMeta || {},
    alias: my_45templatesmc8ukcoO1wMeta?.alias || [],
    redirect: my_45templatesmc8ukcoO1wMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencerszDwsuIdQTPMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencerszDwsuIdQTPMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencerszDwsuIdQTPMeta || {},
    alias: notion_45ambassadors_45influencerszDwsuIdQTPMeta?.alias || [],
    redirect: notion_45ambassadors_45influencerszDwsuIdQTPMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D4Or7iKoGSMeta?.name ?? "notion-form-slug",
    path: _91slug_93D4Or7iKoGSMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93D4Or7iKoGSMeta || {},
    alias: _91slug_93D4Or7iKoGSMeta?.alias || [],
    redirect: _91slug_93D4Or7iKoGSMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackKd4IPgG8wsMeta?.name ?? "notion-callback",
    path: callbackKd4IPgG8wsMeta?.path ?? "/notion/callback",
    meta: callbackKd4IPgG8wsMeta || {},
    alias: callbackKd4IPgG8wsMeta?.alias || [],
    redirect: callbackKd4IPgG8wsMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackbmnEIdRuxXMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackbmnEIdRuxXMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackbmnEIdRuxXMeta || {},
    alias: guest_45callbackbmnEIdRuxXMeta?.alias || [],
    redirect: guest_45callbackbmnEIdRuxXMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callback8O7shCSXXJMeta?.name ?? "oauth-callback",
    path: callback8O7shCSXXJMeta?.path ?? "/oauth/callback",
    meta: callback8O7shCSXXJMeta || {},
    alias: callback8O7shCSXXJMeta?.alias || [],
    redirect: callback8O7shCSXXJMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partners7kKSPZjPL3Meta?.name ?? "partners",
    path: partners7kKSPZjPL3Meta?.path ?? "/partners",
    meta: partners7kKSPZjPL3Meta || {},
    alias: partners7kKSPZjPL3Meta?.alias || [],
    redirect: partners7kKSPZjPL3Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93nhNlBOnnLsMeta?.name ?? "password-reset-token",
    path: _91token_93nhNlBOnnLsMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93nhNlBOnnLsMeta || {},
    alias: _91token_93nhNlBOnnLsMeta?.alias || [],
    redirect: _91token_93nhNlBOnnLsMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexI2TzBn44W6Meta?.name ?? "password-reset",
    path: indexI2TzBn44W6Meta?.path ?? "/password/reset",
    meta: indexI2TzBn44W6Meta || {},
    alias: indexI2TzBn44W6Meta?.alias || [],
    redirect: indexI2TzBn44W6Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingNOez5k7CGrMeta?.name ?? "pricing",
    path: pricingNOez5k7CGrMeta?.path ?? "/pricing",
    meta: pricingNOez5k7CGrMeta || {},
    alias: pricingNOez5k7CGrMeta?.alias || [],
    redirect: pricingNOez5k7CGrMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOj6mohxD9sMeta?.name ?? "privacy-policy",
    path: privacy_45policyOj6mohxD9sMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOj6mohxD9sMeta || {},
    alias: privacy_45policyOj6mohxD9sMeta?.alias || [],
    redirect: privacy_45policyOj6mohxD9sMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: register1LOHAQdOuuMeta?.name ?? "register",
    path: register1LOHAQdOuuMeta?.path ?? "/register",
    meta: register1LOHAQdOuuMeta || {},
    alias: register1LOHAQdOuuMeta?.alias || [],
    redirect: register1LOHAQdOuuMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsUjzHurzjeBMeta?.name ?? undefined,
    path: settingsUjzHurzjeBMeta?.path ?? "/settings",
    meta: settingsUjzHurzjeBMeta || {},
    alias: settingsUjzHurzjeBMeta?.alias || [],
    redirect: settingsUjzHurzjeBMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokens7DiugE4cpqMeta?.name ?? "settings-access-tokens",
    path: access_45tokens7DiugE4cpqMeta?.path ?? "access-tokens",
    meta: access_45tokens7DiugE4cpqMeta || {},
    alias: access_45tokens7DiugE4cpqMeta?.alias || [],
    redirect: access_45tokens7DiugE4cpqMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountHc4Lss7dP7Meta?.name ?? "settings-account",
    path: accountHc4Lss7dP7Meta?.path ?? "account",
    meta: accountHc4Lss7dP7Meta || {},
    alias: accountHc4Lss7dP7Meta?.alias || [],
    redirect: accountHc4Lss7dP7Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminFW3FaZMmUBMeta?.name ?? "settings-admin",
    path: adminFW3FaZMmUBMeta?.path ?? "admin",
    meta: adminFW3FaZMmUBMeta || {},
    alias: adminFW3FaZMmUBMeta?.alias || [],
    redirect: adminFW3FaZMmUBMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingDQrE1aTO4IMeta?.name ?? "settings-billing",
    path: billingDQrE1aTO4IMeta?.path ?? "billing",
    meta: billingDQrE1aTO4IMeta || {},
    alias: billingDQrE1aTO4IMeta?.alias || [],
    redirect: billingDQrE1aTO4IMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsm80SPrGFBXMeta?.name ?? "settings-connections",
    path: connectionsm80SPrGFBXMeta?.path ?? "connections",
    meta: connectionsm80SPrGFBXMeta || {},
    alias: connectionsm80SPrGFBXMeta?.alias || [],
    redirect: connectionsm80SPrGFBXMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainTZREgEGVOPMeta?.name ?? "settings-custom-domain",
    path: custom_45domainTZREgEGVOPMeta?.path ?? "custom-domain",
    meta: custom_45domainTZREgEGVOPMeta || {},
    alias: custom_45domainTZREgEGVOPMeta?.alias || [],
    redirect: custom_45domainTZREgEGVOPMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settings4OViNC42vZMeta?.name ?? "settings-email-settings",
    path: email_45settings4OViNC42vZMeta?.path ?? "email-settings",
    meta: email_45settings4OViNC42vZMeta || {},
    alias: email_45settings4OViNC42vZMeta?.alias || [],
    redirect: email_45settings4OViNC42vZMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: index6OqrZT2ni6Meta?.name ?? "settings",
    path: index6OqrZT2ni6Meta?.path ?? "",
    meta: index6OqrZT2ni6Meta || {},
    alias: index6OqrZT2ni6Meta?.alias || [],
    redirect: index6OqrZT2ni6Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordesFxNNt2eJMeta?.name ?? "settings-password",
    path: passwordesFxNNt2eJMeta?.path ?? "password",
    meta: passwordesFxNNt2eJMeta || {},
    alias: passwordesFxNNt2eJMeta?.alias || [],
    redirect: passwordesFxNNt2eJMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileu6G6LkvPhBMeta?.name ?? "settings-profile",
    path: profileu6G6LkvPhBMeta?.path ?? "profile",
    meta: profileu6G6LkvPhBMeta || {},
    alias: profileu6G6LkvPhBMeta?.alias || [],
    redirect: profileu6G6LkvPhBMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaces8ar38UJQSAMeta?.name ?? "settings-workspaces",
    path: workspaces8ar38UJQSAMeta?.path ?? "workspaces",
    meta: workspaces8ar38UJQSAMeta || {},
    alias: workspaces8ar38UJQSAMeta?.alias || [],
    redirect: workspaces8ar38UJQSAMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorotCLwvLmCjMeta?.name ?? "subscriptions-error",
    path: errorotCLwvLmCjMeta?.path ?? "/subscriptions/error",
    meta: errorotCLwvLmCjMeta || {},
    alias: errorotCLwvLmCjMeta?.alias || [],
    redirect: errorotCLwvLmCjMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successYHpdsCBoTWMeta?.name ?? "subscriptions-success",
    path: successYHpdsCBoTWMeta?.path ?? "/subscriptions/success",
    meta: successYHpdsCBoTWMeta || {},
    alias: successYHpdsCBoTWMeta?.alias || [],
    redirect: successYHpdsCBoTWMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsweDgkOupcOMeta?.name ?? "terms-conditions",
    path: terms_45conditionsweDgkOupcOMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsweDgkOupcOMeta || {},
    alias: terms_45conditionsweDgkOupcOMeta?.alias || [],
    redirect: terms_45conditionsweDgkOupcOMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editklgKf5Mwz4Meta?.name ?? "views-slug-edit",
    path: editklgKf5Mwz4Meta?.path ?? "/views/:slug()/edit",
    meta: editklgKf5Mwz4Meta || {},
    alias: editklgKf5Mwz4Meta?.alias || [],
    redirect: editklgKf5Mwz4Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFeevTZNbC9Meta?.name ?? "views-slug",
    path: indexFeevTZNbC9Meta?.path ?? "/views/:slug()",
    meta: indexFeevTZNbC9Meta || {},
    alias: indexFeevTZNbC9Meta?.alias || [],
    redirect: indexFeevTZNbC9Meta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showS6hgRItdgQMeta?.name ?? undefined,
    path: showS6hgRItdgQMeta?.path ?? "/views/:slug()/show",
    meta: showS6hgRItdgQMeta || {},
    alias: showS6hgRItdgQMeta?.alias || [],
    redirect: showS6hgRItdgQMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexMoK3dNifoPMeta?.name ?? "views-slug-show",
    path: indexMoK3dNifoPMeta?.path ?? "",
    meta: indexMoK3dNifoPMeta || {},
    alias: indexMoK3dNifoPMeta?.alias || [],
    redirect: indexMoK3dNifoPMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: sharexbrgjb9z3zMeta?.name ?? "views-slug-show-share",
    path: sharexbrgjb9z3zMeta?.path ?? "share",
    meta: sharexbrgjb9z3zMeta || {},
    alias: sharexbrgjb9z3zMeta?.alias || [],
    redirect: sharexbrgjb9z3zMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexZbhG8udV1xMeta?.name ?? "views-create",
    path: indexZbhG8udV1xMeta?.path ?? "/views/create",
    meta: indexZbhG8udV1xMeta || {},
    alias: indexZbhG8udV1xMeta?.alias || [],
    redirect: indexZbhG8udV1xMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexUMRyBw0xeNMeta?.name ?? "views",
    path: indexUMRyBw0xeNMeta?.path ?? "/views",
    meta: indexUMRyBw0xeNMeta || {},
    alias: indexUMRyBw0xeNMeta?.alias || [],
    redirect: indexUMRyBw0xeNMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsQxIOiopKAtMeta?.name ?? "views-uploads",
    path: uploadsQxIOiopKAtMeta?.path ?? "/views/uploads",
    meta: uploadsQxIOiopKAtMeta || {},
    alias: uploadsQxIOiopKAtMeta?.alias || [],
    redirect: uploadsQxIOiopKAtMeta?.redirect,
    component: () => import("/codebuild/output/src1283008905/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]